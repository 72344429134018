import './infos.scss';

import { AjaxLoader } from '../../utilities/js/ajax-load-promise.js';
import tippy from 'tippy.js';

class Infos {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-infos',
            loader: null
        };

        this.settings = Object.assign({}, defaults, options);

        this.$info = element;
        this.infoPath = null;
        this.type = element.getAttribute('data-type');
        this.wait = false;

        if (this.settings.loader !== null) {
            this.$loader = this.createElementFromHTML(this.settings.loader);
        }

        if (this.$info.getAttribute(this.settings.initAttr + '-init') === null) {
            this.initialize();
            this.$info.setAttribute(this.settings.initAttr + '-init', 'init');
        }
    }

    initialize () {
        this.infoPath = this.$info.getAttribute(this.settings.initAttr);

        if (this.infoPath !== null) {
            this.setEvents();
        }
    }

    createElementFromHTML (htmlString) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        if (div.children.length > 1) {
            const itemsArray = [];
            Array.prototype.push.apply(itemsArray, div.children);

            return itemsArray;
        } else {
            return div.firstChild;
        }
    }

    setEvents () {
        const infoPath = this.infoPath;
        const $info = this.$info;
        const _this = this;

        if (this.type === 'tooltip') {
            tippy($info, {
                arrow: '<span class="tooltip__arrow"></span>',
                duration: [250, 250],
                offset: [0, 15],
                content: '<b class="loading"><b class="loader icon-minus"><i>loading...</i></b></b>',
                allowHTML: true,
                trigger: 'click',
                interactive: true,
                animation: 'shift-away-subtle',
                appendTo: () => document.body,
                maxWidth: 'none',
                onCreate (instance) {
                    // Setup our own custom state properties
                    instance._isFetching = false;
                    instance._error = null;

                    instance._closeButton = document.createElement('a');
                    instance._closeButton.classList.add('tooltip__close');
                    instance._closeButton.innerHTML = '<span>close</span>';

                    instance._closeButton.addEventListener('click', instance.hide);
                },
                onShow (instance) {
                    if (instance._isFetching || instance._error) {
                        return;
                    }

                    instance._isFetching = true;

                    document.body.classList.add('has--tippy');

                    this.result = AjaxLoader.getData(infoPath);
                    _this.setToolTip(this.result, instance);
                },
                onHidden (instance) {
                    instance.setContent('<b class="loading"><b class="loader icon-minus"><i>loading...</i></b></b>');
                    // Unset these properties so new network requests can be initiated
                    instance._error = null;
                    // instance._closeButton = null;
                    document.body.classList.remove('has--tippy');
                }
            });
        }

        if (this.type === 'overlay') {
            this.$info.addEventListener('click', () => {
                if (this.wait === false) {
                    this.wait = true;
                    this.result = AjaxLoader.getData(infoPath);
                    _this.setOverlay(this.result);
                }
            });
        }

        if (this.type === 'overlay-dashboard') {
            this.$info.addEventListener('click', () => {
                if (this.wait === false) {
                    this.wait = true;
                    this.result = AjaxLoader.getData(infoPath);
                    _this.setOverlayTable(this.result);
                }
            });
        }
    }

    setToolTip (result, instance) {
        result.then((results) => {
            const $result = this.createElementFromHTML(results);
            const $resultContent = this.createElementFromHTML($result.innerHTML);
            $resultContent.appendChild(instance._closeButton);
            instance.setContent($resultContent);
            instance._isFetching = false;
        });
    }

    setOverlay (result) {
        result.then((results) => {
            const $result = this.createElementFromHTML(results);
            const $resultContent = this.createElementFromHTML($result.innerHTML);
            // const $headline = $resultContent.querySelector('[data-overlay="headline"]');
            const $copy = $resultContent.querySelector('[data-overlay="copy"]');

            /* if ($headline.innerHTML === '' && $copy.innerHTML === '') { */
            if ($copy.innerHTML === '') {
                $copy.innerHTML = document.querySelector('[data-overlay-value]').getAttribute('data-overlay-value');
            }

            document.body.appendChild($resultContent);
            this.bodyScrollLock('enable');

            // const $closeButton = document.querySelector('[data-overlay="close"]');
            const $overlay = document.querySelector('[data-overlay="root"]');
            $overlay.classList.add('is--visible');

            $overlay.addEventListener('click', (e) => {
                if (e.target.classList.contains('is--visible') || e.target.classList.contains('overlay__container') || e.target.classList.contains('overlay__close')) {
                    this.closeOverlay();
                    this.wait = false;
                }
            });
        });
    }

    setOverlayTable (result) {
        result.then((results) => {
            const $result = this.createElementFromHTML(results);
            const $resultContent = this.createElementFromHTML($result.innerHTML);

            document.body.appendChild($resultContent);
            this.bodyScrollLock('enable');

            // const $closeButton = document.querySelector('[data-overlay="close"]');
            const $overlay = document.querySelector('[data-overlay="root"]');
            $overlay.classList.add('is--visible');

            $overlay.addEventListener('click', (e) => {
                if (e.target.classList.contains('is--visible') || e.target.classList.contains('overlay__container') || e.target.classList.contains('overlay__close')) {
                    this.closeOverlay();
                    this.wait = false;
                }
            });
        });
    }

    closeOverlay () {
        const $overlay = document.querySelector('.overlay');

        if ($overlay) {
            $overlay.classList.remove('is--visible');

            setTimeout(() => {
                $overlay.remove();
            }, 700);

            this.bodyScrollLock('disable');
        }
    }

    bodyScrollLock (status) {
        if (status === 'disable') {
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            window.scrollTo(0, this.scrollPosition);
        }

        if (status === 'enable') {
            this.scrollPosition = window.pageYOffset;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = '-' + this.scrollPosition + 'px';
            document.body.style.width = '100%';
        }
    }
}

export { Infos };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    const $infos = $context.querySelectorAll('[data-infos]');

    $infos.forEach(($info) => {
        const infoAPI = new Infos($info, {
            loader: window.OPTIONS.loader
        });
        $info.API = infoAPI;
    });
});
